// Substitute current year in copyrights
(function() {
    const copyrightContent = document.querySelector('.copyrights__content');
    if (copyrightContent) {
        const currentYear = new Date().getFullYear();
        copyrightContent.innerHTML += currentYear;
    }
})();

// Menu smooth scrolling
(() => {
    const sectionLinks = document.querySelectorAll('[data-section-ref]');

    if (!sectionLinks) {
        return;
    }

    for (let sectionLink of sectionLinks) {
        sectionLink.onclick = function(ref) {
            const dataSectionName = ref.target.getAttribute("data-section-ref");
            const noSmooth = ref.target.hasAttribute("data-section-no-smooth");
            const targetSection = document.getElementById(dataSectionName);

            if (targetSection) {
                targetSection.scrollIntoView({
                    block: "start",
                    inline: "nearest",
                    behavior: noSmooth ? "auto" : "smooth"
                });
            }
        }
    }
})();
